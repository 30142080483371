import { useCallback, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useSelector } from 'react-redux';
import { keyBy, keys } from 'lodash';
import {
  Input,
  Textarea,
  Flex,
  Box,
  InputGroup,
  InputRightAddon,
  Text,
  Checkbox,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import AddressAutocomplete from './AddressAutocomplete';
import TreeInput, { TLeaf } from './TreeInput';
import { formatDate, formatPhoneNumber, getReadableColor } from '../../helpers';
import SelectInput, { TSelectInputData } from './SelectInput';
import FormGroup from './FormGroup';
import { CirlceIcon, allIcons } from '../utils/Icon';
import RSelect from './RSelect';
import { TState } from '../../redux/store';
import { useCanI, useGetSelect } from '../../redux/actions';

export type TAction = {
  title?: string;
  label?: string;
  level?: number;
  isChecked?: boolean;
  onChange?: any;
  isIndeterminate?: boolean;
};

export type TField = {
  _id?: string;
  name_id?: string;
  type: string;
  name?: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  options?: {
    label: string;
    value: any;
  }[];
  colors?: string[];
  unity?: string;
  list?: TLeaf[];
  actions?: TAction[];
  selected?: any;
  addressFields?: Record<string, boolean>;
  group?: string;
  forced?: boolean;
  condition?: (formData: Record<string, any>) => boolean;
  placeholder?: string;
  isMulti?: boolean;
};

const useForm = (
  fields: TField[] = [],
  onSubmit?: (x?: any, y?: any, z?: any) => Promise<any>,
  planable?: boolean,
  noRowData?: boolean,
) => {
  const modalType = useSelector((state: TState) => state.modalType);
  const dataInputs = useSelector((state: TState) => state.dataInputs);
  const rowData = useSelector((state: TState) => state.rowData);
  const userList = useSelector((state: TState) => state.userList);

  const getSelectByName = useGetSelect();
  const canI = useCanI();

  const [pending, setPending] = useState(false);
  const [formData, setFormData] = useState<Record<string, any>>(
    noRowData ? {} : rowData || {},
  );

  const isAdd = modalType === 'addEntry';

  const groupOptions = keys(keyBy(dataInputs, 'group'))
    .filter((f) => f && f !== 'undefined')
    .map((v) => ({
      label: v,
      value: v,
    }));

  useEffect(() => {
    !noRowData && !/planning/i.test(modalType) && setFormData({});
  }, [modalType]);

  useEffect(() => {
    !noRowData && setFormData(rowData || {});
  }, [rowData]);

  const handleSubmit = async () => {
    const resp = await onSubmit?.(formData);
    return resp;
  };

  const handleOnChange = (label: string, e: any, forced?: boolean) => {
    setFormData((d) => ({
      ...d,
      [label]: forced ? e : e ?? '',
    }));
  };

  const handleReset = (name: string) => {
    setFormData((d: any) => ({
      ...d,
      [name]: null,
      [`${name}_street`]: ``,
      [`${name}_city`]: '',
      [`${name}_dept`]: '',
      [`${name}_postal`]: '',
      [`${name}_addressDescription`]: '',
    }));
  };

  const getInput = (field: TField) => {
    const name = field.name_id || field.name || field._id || '';

    const usersById: Record<string, any> = keyBy(userList, '_id');

    const isDisabled =
      field.disabled ||
      (/editEntry/.test(modalType) &&
        !canI(name, 'update') &&
        !canI(`${name}_street`, 'update') &&
        !canI(`${name}_city`, 'update') &&
        !canI(`${name}_postal`, 'update') &&
        !canI(`${name}_dept`, 'update'));

    const InputSU = ({
      value,
      color,
      options,
      isMulti,
    }: {
      value?: any;
      color?: string | null;
      options: { label: string; value: any; disabled?: boolean }[];
      isMulti?: boolean;
    }) => {
      const boxColor = color || value?.color;

      const handleChange = useCallback(
        (e: any) =>
          handleOnChange(
            name,
            isMulti ? e.map((opt: any) => opt.value) : e?.value,
          ),
        [isMulti],
      );

      return (
        <Flex alignItems='center'>
          <RSelect
            isDisabled={isDisabled}
            closeMenuOnSelect={false}
            onChange={handleChange}
            value={value}
            options={options?.filter((o) => !o.disabled)}
            placeholder={field.placeholder}
            isMulti={isMulti}
            styles={{
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: getReadableColor(data.color),
              }),
              multiValue: (styles, { data }) => ({
                ...styles,
                backgroundColor: data.color,
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: getReadableColor(data.color),
                ':hover': {
                  opacity: 0.5,
                },
              }),
            }}
          />
          {boxColor && (
            <Box
              borderRadius={5}
              ml={3}
              pr={5}
              backgroundColor={boxColor}
              width='32px'
              height='32px'
            />
          )}
          {!isDisabled && (
            <CirlceIcon
              className='xmark'
              ml={3}
              as={allIcons.HiMiniXMark}
              onClick={() => handleReset(name)}
            />
          )}
        </Flex>
      );
    };

    switch (field.type) {
      case 'users':
        const userOptions: any =
          field.name_id === 'created_by' || !field.options
            ? userList.map((ul) => ({
                value: ul._id,
                label: ul.name,
                color: ul.userColor,
              }))
            : field.options?.map((option) => {
                const opt = usersById[option as any] || {};
                return {
                  value: opt._id,
                  label: opt.name,
                  color: opt.userColor,
                };
              });

        const userCurrOption =
          formData[name] == null
            ? null
            : field.isMulti
            ? userOptions.filter((option: any) =>
                formData[name].includes(option.value),
              )
            : userOptions.find(
                (option: any) => option.value === formData[name],
              );

        return (
          <InputSU
            value={userCurrOption}
            options={userOptions.filter((opt: any) => opt.value)}
            isMulti={field.isMulti}
          />
        );
      case 'select':
        const selectInput = field.name_id
          ? getSelectByName(field.name_id)
          : null;

        const options =
          selectInput?.formOptions ||
          field.options?.map((fo, idx) => ({
            ...fo,
            color: field.colors?.[idx],
          }));

        const currOption =
          formData[name] == null
            ? null
            : (options as Partial<TSelectInputData>[])?.find(
                (opt) => `${opt.value}` === `${formData[name]}`,
              );

        return (
          <InputSU
            value={currOption}
            color={currOption?.color || ''}
            options={options || []}
          />
        );
      case 'multiselect':
        try {
          const selectInput = field.name_id
            ? getSelectByName(field.name_id)
            : null;

          const multiValue = formData[name]?.map((data: any) =>
            selectInput?.formOptions?.find((fo) => fo.value === data),
          );

          return (
            <Flex alignItems='center'>
              <RSelect
                closeMenuOnSelect={false}
                isDisabled={isDisabled}
                isMulti
                onChange={(e) =>
                  handleOnChange(
                    name,
                    e.map((multiVal: any) => multiVal.value),
                  )
                }
                value={multiValue || []}
                options={selectInput?.formOptions?.filter((o) => !o.disabled)}
                styles={{
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: getReadableColor(data.color),
                  }),
                  multiValue: (styles, { data }) => ({
                    ...styles,
                    backgroundColor: data.color,
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: getReadableColor(data.color),
                    ':hover': {
                      opacity: 0.5,
                    },
                  }),
                }}
              />
              {!isDisabled && (
                <CirlceIcon
                  className='xmark'
                  ml={3}
                  as={allIcons.HiMiniXMark}
                  onClick={() => handleReset(name)}
                />
              )}
            </Flex>
          );
        } catch (e) {
          return <></>;
        }
      case 'address':
        const addr = {
          street: formData[`${name}_street`],
          city: formData[`${name}_city`],
          postal: formData[`${name}_postal`],
          id: formData[name],
        };

        return (
          <Flex flexWrap='wrap'>
            <Box w='100%'>
              <FormGroup
                field={field}
                prefixLabel='adresse'
                planable={planable}
                input={
                  <AddressAutocomplete
                    isDisabled={isDisabled}
                    setFormData={setFormData}
                    value={addr.street}
                    label={name}
                    setPending={setPending}
                    type='street'
                  />
                }
              />
            </Box>
            <Flex w='100%' gap={2} alignItems='end' flexWrap='wrap'>
              <Box w={['100%', '50%']}>
                <FormGroup
                  field={field}
                  prefixLabel='ville'
                  input={
                    <AddressAutocomplete
                      isDisabled={isDisabled}
                      setFormData={setFormData}
                      value={addr.city}
                      label={name}
                      setPending={setPending}
                      type='city'
                    />
                  }
                />
              </Box>
              <Box flexGrow={1}>
                <FormGroup
                  field={field}
                  prefixLabel='code postal'
                  input={
                    <AddressAutocomplete
                      isDisabled={isDisabled}
                      setFormData={setFormData}
                      value={addr.postal}
                      label={name}
                      setPending={setPending}
                      type='postal'
                    />
                  }
                />
              </Box>
              {!addr.id && (
                <Tooltip label='Adresse non valide'>
                  <span>
                    <CirlceIcon
                      className='xmark'
                      as={allIcons.HiMiniExclamationTriangle}
                      color='orange.400'
                      mb={0.5}
                    />
                  </span>
                </Tooltip>
              )}
              {!isDisabled && (
                <CirlceIcon
                  className='xmark'
                  as={allIcons.HiMiniXMark}
                  onClick={() => handleReset(name)}
                  mb={0.5}
                />
              )}
            </Flex>
          </Flex>
        );
      case 'tree':
        return (
          <TreeInput
            list={field.list || []}
            actions={field.actions || []}
            selected={field.selected}
            onChange={(e: any) => handleOnChange(name, e)}
          />
        );
      case 'selectInput':
        return (
          <SelectInput
            orderedOptions={formData.orderedOptions}
            setFormData={(values: any) => {
              handleOnChange('options', values);
              handleOnChange('orderedOptions', values);
            }}
          />
        );
      case 'multiSelect':
        return (
          <RSelect
            isDisabled={isDisabled}
            closeMenuOnSelect={false}
            defaultValue={field.options?.filter((option) =>
              rowData.options?.includes(option.value),
            )}
            isMulti
            onChange={(opts) => {
              handleOnChange(
                'options',
                opts.map((opt: any) => opt.value),
              );
            }}
            options={field.options}
          />
        );
      case 'checkbox':
        return (
          <Flex h='32px' alignItems='center'>
            <Checkbox
              isDisabled={isDisabled}
              width='24px'
              height='24px'
              isChecked={
                field.forced ? formData[name] !== undefined : formData[name]
              }
              colorScheme={
                field.forced && formData[name] === false ? 'red' : 'blue'
              }
              onChange={(e: any) =>
                handleOnChange(
                  name,
                  !field.forced
                    ? e?.target?.checked
                    : formData[name] === true
                    ? false
                    : formData[name] === false
                    ? undefined
                    : true,
                  field.forced,
                )
              }
            />
          </Flex>
        );
      case 'password':
      case 'text':
        return (
          <Input
            isDisabled={isDisabled}
            pl={2}
            h='32px'
            type={field.type}
            onChange={(e: any) => handleOnChange(name, e?.target?.value)}
            value={formData[name] ?? ''}
          />
        );
      case 'date':
        return (
          <Input
            isDisabled={isDisabled}
            pl={2}
            h='32px'
            type={field.type}
            onChange={(e: any) => handleOnChange(name, e?.target?.value)}
            value={formatDate(formData[name], true) ?? ''}
          />
        );
      case 'createSelect':
        return (
          <>
            <CreatableSelect
              isClearable
              options={groupOptions}
              defaultValue={{
                label: formData[name] ?? '',
                value: formData[name] ?? '',
              }}
              formatCreateLabel={(text) => `Ajouter ${text}`}
              onChange={(e: any) => handleOnChange(name, e?.value)}
            />
            <Text fontStyle='italic' fontSize='.8em'>
              Pour créer un nouveau groupe, taper le nom puis <b>Ajouter</b>
            </Text>
          </>
        );
      case 'tel':
        return (
          <Input
            isDisabled={isDisabled}
            pl={2}
            minH='32px'
            type={field.type}
            onChange={(e: any) => handleOnChange(name, e?.target?.value)}
            onBlur={(e: any) =>
              handleOnChange(name, formatPhoneNumber(e?.target?.value))
            }
            value={formData[name] ?? ''}
          />
        );
      case 'number':
        return (
          <InputGroup>
            <Input
              isDisabled={isDisabled}
              minH='32px'
              type='number'
              onChange={(e: any) => handleOnChange(name, e?.target?.value)}
              value={formData[name] ?? ''}
            />
            {field.unity && (
              <InputRightAddon h='32px'>{field.unity}</InputRightAddon>
            )}
          </InputGroup>
        );
      case 'switch':
        return (
          <Flex alignItems='center' minH='32px'>
            <Switch
              isDisabled={isDisabled}
              alignItems='center'
              isChecked={formData[name]}
              onChange={(e: any) => handleOnChange(name, e.target.checked)}
              size='lg'
            />
          </Flex>
        );
      case 'title':
        return undefined;
      case 'date_range':
      case 'number_range':
        const isDate = /date/.test(field.type);

        const handleRangeChange = (e: any) =>
          !e?.target?.value
            ? undefined
            : isDate
            ? e?.target?.value
            : Number(e?.target?.value);

        return (
          <Flex gap={2}>
            <Input
              isDisabled={isDisabled}
              pl={2}
              h='32px'
              type={isDate ? 'date' : 'number'}
              onChange={(e: any) =>
                handleOnChange(name, [
                  handleRangeChange(e),
                  formData[name]?.[1] ||
                    (isDate && e?.target?.value ? e?.target?.value : undefined),
                ])
              }
              value={
                (isDate
                  ? formatDate(formData[name]?.[0], true)
                  : formData[name]?.[0]) ?? ''
              }
            />
            <Input
              isDisabled={isDisabled}
              pl={2}
              h='32px'
              type={isDate ? 'date' : 'number'}
              onChange={(e: any) =>
                handleOnChange(name, [
                  formData[name]?.[0],
                  handleRangeChange(e),
                ])
              }
              value={
                (isDate
                  ? formatDate(formData[name]?.[1], true)
                  : formData[name]?.[1]) ?? ''
              }
            />
          </Flex>
        );
      default:
        return (
          <Textarea
            isDisabled={isDisabled}
            p={2}
            minH='35px'
            h='max-content'
            onChange={(e) => {
              e.target.style.height = 'auto'; // Reset height
              e.target.style.height = `${e.target.scrollHeight + 2}px`; // Set height to scroll height
              handleOnChange(name, e.target.value);
            }}
            value={formData[name] ?? ''}
          />
        );
    }
  };

  const getForm = (f: any) => {
    const i = getInput(f);
    return <FormGroup field={f} input={i} />;
  };

  const getFields = (f: any[] = []) =>
    f.reduce(
      (prev, c: any) =>
        c.name_id &&
        c.type !== 'title' &&
        !canI(c.name_id, isAdd ? 'create' : 'read') &&
        !canI(`${c.name_id}_street`, isAdd ? 'create' : 'read') &&
        !canI(`${c.name_id}_city`, isAdd ? 'create' : 'read') &&
        !canI(`${c.name_id}_postal`, isAdd ? 'create' : 'read') &&
        !canI(`${c.name_id}_dept`, isAdd ? 'create' : 'read')
          ? prev
          : [...prev, getForm(c)],
      [],
    ) as JSX.Element[];

  const filteredFields = fields.filter(
    (field) => !field.condition || field.condition?.(formData),
  );

  const Form = getFields(filteredFields);

  return {
    Form,
    handleSubmit,
    formData,
    setFormData,
    pending,
  };
};

export default useForm;
