import { intersection, isArray, isEmpty, keyBy, keys } from 'lodash';
import { useCallback } from 'react';
import { regCompare } from '../../../helpers';
import { useSelector } from 'react-redux';
import { TState } from '../../../redux/store';
import moment from 'moment';

const useFilters = () => {
  const dataInputs = useSelector((state: TState) => state.dataInputs);
  const dataInputsById = keyBy(dataInputs, 'name_id');

  const filtersFn = useCallback(
    (row: Record<string, any>, columnId: string, filterValue: any) => {
      const { type } = dataInputsById[columnId] || {};

      const value = row.original[columnId];

      return isEmpty(filterValue)
        ? true
        : /_city|_street|_postal|_dept/.test(columnId)
        ? regCompare(
            `${/_postal/.test(columnId) ? '^' : ''}${filterValue}`,
            value,
            'ig',
          )
        : /number/.test(type) &&
          (!filterValue[0] || value >= filterValue[0]) &&
          (!filterValue[1] || value <= filterValue[1])
        ? true
        : /date/.test(type) &&
          (!filterValue[0] ||
            moment(value).isSameOrAfter(filterValue[0], 'date')) &&
          (!filterValue[1] ||
            moment(value).isSameOrBefore(filterValue[1], 'date'))
        ? true
        : isArray(value) && intersection(value, filterValue).length
        ? true
        : filterValue.includes(value);
    },
    [keys(dataInputsById).join('-')],
  );

  return filtersFn;
};

export default useFilters;
