import { useEffect, useRef } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider, useSelector } from 'react-redux';

import store, { TState, useActions } from './redux/store';

import App from './App';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import theme from './theme';

import './App.css';
import './index.css';
import moment from 'moment';
import { useAllTabs } from './redux/actions';
import { updateFavicon } from './helpers';

const domNode = document.getElementById('root');
const root = createRoot(domNode as HTMLElement);

const MAX_MOBILE_SIZE = 480;
const MAX_TABLET_SIZE = 1200;

const InitApp = () => {
  const token = useSelector((state: TState) => state.token);
  const isOpen = useSelector((state: TState) => state.isOpen);
  const currentTab = useSelector((state: TState) => state.currentTab);
  const tabs = useSelector((state: TState) => state.tabs);
  const modalType = useSelector((state: TState) => state.modalType);
  const login = useSelector((state: TState) => state.login);
  const user = useSelector((state: TState) => state.user);
  const settingsTabs = useSelector((state: TState) => state.settingsTabs);
  const isEditMode = useSelector((state: TState) => state.isEditMode);
  const calendarCurrentDay = useSelector(
    (state: TState) => state.calendarCurrentDay,
  );

  const allTabs = useAllTabs();

  const firstDayOfWeek = useRef(calendarCurrentDay);

  const {
    setGlobalFilter,
    setCurrentTab,
    setRowData,
    setIsMobile,
    setIsTablet,
    onOpen,
    setModalType,
    setPlanningAddress,
    getUsersList,
    getInputs,
    getDocumentList,
    getTabs,
    getData,
    getUserRoleContext,
    fetchAppointments,
    getDataCount,
    setTabToUpdate,
    setAppointsHistory,
    setRowHistory,
    setFilesStatus,
    setFilesComms,
  } = useActions();

  useEffect(() => {
    token && !login && getUserRoleContext();
  }, [token, login]);

  useEffect(() => {
    if (token && user?._id) {
      getUsersList({ forced: true });
      getTabs();

      if (!user?.isSuper) {
        getInputs({ forced: true });
        getDocumentList({ forced: true });
        getDataCount();
      }
    }
  }, [user?._id]);

  useEffect(() => {
    const newFirstDayOfWeek = moment(calendarCurrentDay).startOf('isoWeek');
    // const newWeek = !newFirstDayOfWeek.isSame(firstDayOfWeek.current, 'date');
    if (currentTab?.isPlanning || /addToPlanning/.test(modalType)) {
      firstDayOfWeek.current = newFirstDayOfWeek.toISOString();
      fetchAppointments(newFirstDayOfWeek);
    }
  }, [calendarCurrentDay]);

  useEffect(() => {
    const localCurrent = localStorage.getItem('currentTab');
    const prevTab = allTabs[localCurrent || ''] || tabs[0] || {};
    const customPrev = prevTab?.isSetting || prevTab?.isPlanning;
    const customCurr = currentTab?.isSetting || currentTab?.isPlanning;
    const needResetSearch =
      (customPrev && !customCurr) || (customCurr && !customPrev);

    if (needResetSearch) {
      setGlobalFilter('');
    }

    const customTab = currentTab?.isSetting || currentTab?.isPlanning;

    if (!customTab && !isEditMode) {
      getData();
    }

    if (!currentTab?.isSetting) {
      setTabToUpdate(currentTab);
    }

    currentTab?._id && localStorage.setItem('currentTab', currentTab?._id);
  }, [currentTab?._id]);

  useEffect(() => {
    if (currentTab?.isSetting) {
      setTabToUpdate(settingsTabs[0]);
    }
  }, [currentTab]);

  useEffect(() => {
    const localCurrent = localStorage.getItem('currentTab');
    setCurrentTab(allTabs[localCurrent || ''] || tabs[0]);
  }, [allTabs]);

  useEffect(() => {
    if (/evisioncrm|localhost/.test(window.location.hostname)) {
      localStorage.setItem('company', window.location.pathname.split('/')[1]);
    } else {
      const urls = window.location.hostname.split('.');
      const company = urls[urls.length - 2];

      localStorage.setItem('company', company);
      updateFavicon(company);
    }

    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < MAX_MOBILE_SIZE);
      setIsTablet(
        window.innerWidth >= MAX_MOBILE_SIZE &&
          window.innerWidth < MAX_TABLET_SIZE,
      );
    });
  }, []);

  useEffect(() => {
    if (modalType) {
      onOpen();
    }
  }, [modalType]);

  useEffect(() => {
    if (!isOpen) {
      setAppointsHistory({});
      setRowHistory({});
      setFilesStatus({});
      setFilesComms({});
      setRowData({});
      setModalType('');
      setPlanningAddress('');
    }
  }, [isOpen]);

  return <></>;
};

const Root = () => {
  return (
    <Provider store={store}>
      <InitApp />
      <App />
    </Provider>
  );
};

root.render(
  <ChakraProvider theme={theme}>
    <DndProvider backend={HTML5Backend}>
      <Root />
    </DndProvider>
  </ChakraProvider>,
);
