import { useSelector } from 'react-redux';
import { flexRender, RowData } from '@tanstack/react-table';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Icon,
  Badge,
  Checkbox,
  Tooltip,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { colors, theme } from '../../../theme';
import {
  formatDate,
  formatPhoneNumber,
  getReadableColor,
  isEmpty,
} from '../../../helpers';
import { TABLE_FONT_SIZE, TTableContainer } from '../TableContainer';
import { allIcons, CirlceIcon } from '../../utils/Icon';
import { TState } from '../../../redux/store';
import Loader from '../../common/Loader';
import DynamicFilters from './DynamicFilters';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    _id: string;
  }
}

const badgeStyle = {
  px: 1.5,
  py: 0.5,
  borderRadius: 30,
  fontSize: theme.fontSize.xSmall,
};

const TableCrm = ({
  parentRef,
  table,
  totalSize,
  virtualRows,
  noResize,
  isPending,
  onEditClick,
  getHeaderGroups,
  rows,
}: TTableContainer & { noResize?: boolean }) => {
  const darkLight = useSelector((state: TState) => state.darkLight);
  const noFilter = useSelector((state: TState) => state.noFilter);

  const headerHeight = '54px';

  return noFilter ? (
    <Flex
      alignItems='center'
      bgColor={`${colors.main}.50`}
      w='fit-content'
      p={2}
      borderRadius={5}
      gap={3}
    >
      Aucune colonne défini dans l'onglet principal pour cette page, voir le
      mode édition
    </Flex>
  ) : (
    <>
      <DynamicFilters table={table} />
      {isPending && <Loader />}
      <Box
        ref={parentRef}
        id='reactTableContainer'
        overflow='auto'
        h={isPending ? 0 : '100%'}
        w='100%'
        borderRadius='15px'
        position='relative'
        whiteSpace='nowrap'
        bgColor={theme.navbarBg[darkLight]}
      >
        <Table w='max-content' id='reactTable' border='none'>
          <Thead h={headerHeight} position='sticky' top={0} left={0} zIndex={1}>
            {getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup?.id} h={headerHeight}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.column.columnDef.meta?._id}
                    p={0}
                    colSpan={header.colSpan}
                    style={{
                      minWidth: `${header.column.columnDef.minSize}px`,
                      width: `${header.getSize()}px`,
                      maxWidth: `${header.getSize()}px`,
                    }}
                    transition='all 0.3s ease'
                    position='relative'
                    backgroundColor={theme.normal[darkLight]}
                    color={theme.mainBis[darkLight]}
                    fontSize={TABLE_FONT_SIZE}
                    fontWeight='bold'
                  >
                    {noResize ? null : (
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            table.options.columnResizeDirection
                          } ${
                            header.column.getIsResizing() ? 'isResizing' : ''
                          }`,
                          cursor: 'ew-resize',
                        }}
                      />
                    )}
                    <Flex
                      alignItems='center'
                      direction='row'
                      borderBottomWidth='1px'
                      borderRightWidth='1px'
                      p={1.5}
                      h={headerHeight}
                      textAlign='center'
                    >
                      <Box
                        flexGrow={1}
                        onClick={header.column.getToggleSortingHandler()}
                        cursor='pointer'
                        my={1}
                        whiteSpace='wrap'
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                        {
                          {
                            asc: <Icon as={ChevronUpIcon} />,
                            desc: <Icon as={ChevronDownIcon} />,
                          }[header.column.getIsSorted() as string]
                        }
                      </Box>
                      {/* {filterActive && (
                        <Filter column={header.column} table={table} />
                      )} */}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody position='relative' h={`${totalSize}px`}>
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];

              return (
                row && (
                  <Tr
                    key={row.original._id}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '35px',
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                    backgroundColor={
                      virtualRow.index % 2
                        ? theme.none[darkLight]
                        : theme.lighter[darkLight]
                    }
                    color={theme.main[darkLight]}
                    _hover={{
                      backgroundColor: theme.light[darkLight],
                      cursor: 'pointer',
                    }}
                  >
                    {row?.getVisibleCells().map((cell) => {
                      const meta = cell.column.columnDef.meta as any;
                      const originalRow = row.original || {};
                      const color = meta?.colors?.[originalRow[meta?.colorKey]];

                      return (
                        <Td
                          p={0}
                          textOverflow={
                            !/select|users/.test(meta.type) ? 'ellipsis' : ''
                          }
                          key={cell?.id}
                          style={{
                            minWidth: `${cell.column.columnDef.minSize}px`,
                            width: `${cell.column.getSize()}px`,
                            maxWidth: `${cell.column.getSize()}px`,
                          }}
                          transition='all 0.3s ease'
                          overflow={'hidden'}
                          fontWeight={color ? 'bold' : 'auto'}
                          textAlign='center'
                          height='35px'
                          cursor={
                            !meta.tableIndex && meta.type !== 'tel'
                              ? 'pointer'
                              : 'default'
                          }
                          onClick={
                            meta.type === 'tel'
                              ? () => {
                                  // handleCall(`${cell.renderValue()}`);
                                }
                              : !meta.tableIndex
                              ? () => onEditClick(row)
                              : () => {}
                          }
                          fontSize={TABLE_FONT_SIZE}
                        >
                          {meta.isMulti ? (
                            originalRow[meta.colorKey]?.map(
                              (valIdx: number) => (
                                <Badge
                                  key={`${valIdx}`}
                                  {...badgeStyle}
                                  ml={1}
                                  backgroundColor={
                                    meta?.colors?.[valIdx] || 'auto'
                                  }
                                  color={
                                    meta?.colors?.[valIdx]
                                      ? getReadableColor(meta?.colors?.[valIdx])
                                      : 'black'
                                  }
                                >
                                  {meta.options[valIdx]}
                                </Badge>
                              ),
                            ) || '-'
                          ) : meta.showValidation ? (
                            <Flex justifyContent='center'>
                              {`${cell.renderValue()}` === 'non' && (
                                <Tooltip
                                  label='Adresse non trouvé'
                                  placement='left'
                                >
                                  <span>
                                    <CirlceIcon
                                      as={
                                        allIcons[
                                          `${cell.renderValue()}` === 'oui'
                                            ? 'HiCheck'
                                            : 'HiMiniExclamationTriangle'
                                        ]
                                      }
                                      color={
                                        `${cell.renderValue()}` === 'oui'
                                          ? 'auto'
                                          : 'orange.400'
                                      }
                                    />
                                  </span>
                                </Tooltip>
                              )}
                            </Flex>
                          ) : meta.tableIndex ? (
                            <>
                              <Checkbox
                                mr={1}
                                isChecked={row.getIsSelected()}
                                onChange={row.getToggleSelectedHandler()}
                              />
                              {virtualRow.index + 1}
                            </>
                          ) : color ? (
                            <Badge
                              {...badgeStyle}
                              backgroundColor={color || 'auto'}
                              color={color ? getReadableColor(color) : 'black'}
                            >{`${cell.renderValue() ?? '-'}`}</Badge>
                          ) : meta?.type === 'date' ? (
                            formatDate(
                              `${cell.renderValue()}`,
                              false,
                              meta._id === 'created_at'
                                ? 'DD/MM/YYYY HH:mm'
                                : '',
                            )
                          ) : meta?.type === 'tel' ? (
                            <a
                              href={`tel:+33${formatPhoneNumber(
                                `${cell.renderValue()}`,
                                true,
                              ).replace(/^0/, '')}`}
                            >
                              {formatPhoneNumber(`${cell.renderValue()}`, true)}
                            </a>
                          ) : meta?.type === 'switch' &&
                            `${cell.renderValue()}` === 'true' ? (
                            <CirlceIcon
                              as={allIcons.HiCheck}
                              bgColor='transparent'
                              m='auto'
                            />
                          ) : meta?.type === 'switch' &&
                            `${cell.renderValue()}` === 'false' ? (
                            '-'
                          ) : (
                            `${
                              isEmpty(cell.renderValue())
                                ? '-'
                                : cell.renderValue()
                            }`
                          )}
                          {cell.renderValue() != null && meta?.unity
                            ? ` ${meta?.unity}`
                            : ''}
                        </Td>
                      );
                    })}
                  </Tr>
                )
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default TableCrm;
