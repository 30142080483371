import { Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { entries } from 'lodash';
import {
  deleteDatas,
  deleteInputs,
  deleteRoles,
  deleteUsers,
  patchData,
  updateInputs,
  updateUsers,
} from '../../../api/tabsApi';
import useForm from '../../form/Form';
import { TColumnData } from '../../tabs/type';
import { TState, useActions } from '../../../redux/store';
import { useCanI, useSubTabs } from '../../../redux/actions';

const useManageActions = () => {
  const tabToUpdate = useSelector((state: TState) => state.tabToUpdate);
  const currentTab = useSelector((state: TState) => state.currentTab);
  const rowSelected = useSelector((state: TState) => state.rowSelected);
  const roleList = useSelector((state: TState) => state.roleList);
  const rowData = useSelector((state: TState) => state.rowData);
  const isSuper = useSelector((state: TState) => state.isSuper);
  const isAdmin = useSelector((state: TState) => state.isAdmin);
  const {
    setRowSelected,
    onClose,
    getInputs,
    getRolesList,
    getUsersList,
    getData,
  } = useActions();

  const canI = useCanI();
  const subTabs = useSubTabs();

  let inputs = subTabs.reduce(
    (prev, tab) => [
      ...prev,
      ...tab.tableColumns
        .filter(
          (col) =>
            /select|users/.test(col.type) &&
            !prev.find((p) => p._id === col._id),
        )
        .map((col) => ({
          ...col,
          parent_id: tab._id,
          type: col.type === 'text' ? '' : col.type,
        })),
    ],
    [] as TColumnData[],
  );

  const options = roleList?.map((role) => ({
    label: role.name,
    value: role._id,
  }));

  const userInputs = [
    {
      label: 'Role',
      name: 'role_id',
      type: 'select',
      options,
      selected: rowData.role_id,
      condition: () => !isSuper,
    },
    {
      label: 'Utilisateurs supervisés',
      name: 'assignedUsers',
      type: 'users',
      isMulti: true,
      selected: rowData.assignedUsers,
      condition: () => !isSuper,
    },
    {
      label: 'Admin',
      name: 'isAdmin',
      type: 'checkbox',
      condition: () => isSuper || isAdmin,
      forced: true,
    },
    {
      label: 'Planifiable',
      name: 'isPlanable',
      type: 'checkbox',
      forced: true,
    },
    { label: 'Désactivé', name: 'blocked', type: 'checkbox', forced: true },
  ];

  const inputsInputs = [
    {
      type: 'createSelect',
      label: 'Groupe',
      name: 'group',
    },
    {
      type: 'checkbox',
      label: 'Champ obligatoire',
      name: 'required',
      forced: true,
    },
  ];

  if (tabToUpdate?.isDuplicate) {
    inputs = [
      {
        label: 'Retirer doublon',
        name: 'isDuplicate',
        type: 'checkbox',
      } as any,
    ];
  }

  const { Form: FormInputs, formData: formDataInputs } = useForm(inputsInputs);
  const { Form: FormUsers, formData: formDataUsers } = useForm(userInputs);
  const { Form, formData } = useForm(inputs);

  const handleAction = async () => {
    onClose();

    if (tabToUpdate?.isDuplicate && formData.isDuplicate === true) {
      formData.isDuplicate = false;
    }

    switch (true) {
      case /inputs/.test(tabToUpdate?._id || ''):
        await updateInputs({ ids: rowSelected, ...formDataInputs });
        getInputs({ forced: true });
        break;
      // case /roles/.test(tabToUpdate?._id || ''):
      //   await deleteRoles(rowSelected);
      //   await getRolesList(true);
      //   break;
      case /users/.test(tabToUpdate?._id || ''):
        await updateUsers({ ids: rowSelected, ...formDataUsers });
        getUsersList({ forced: true });
        break;
      default:
        await patchData({ ids: rowSelected, ...formData });
        getData();
    }

    setRowSelected([]);
  };

  const handleDeleteAction = async () => {
    onClose();

    switch (true) {
      case /inputs/.test(tabToUpdate?._id || ''):
        await deleteInputs(rowSelected);
        getInputs({ forced: true });
        break;
      case /roles/.test(tabToUpdate?._id || ''):
        await deleteRoles(rowSelected);
        getRolesList({ forced: true });
        break;
      case /users/.test(tabToUpdate?._id || ''):
        await deleteUsers(rowSelected);
        getUsersList({ forced: true });
        break;
      default:
        await deleteDatas(rowSelected);
        await getData();
    }

    setRowSelected([]);
  };

  const deletePermission =
    isAdmin || (currentTab?._id && canI(currentTab?._id, 'delete'));

  const renderDeleteInput = (
    <Text>
      Vous confirmez vouloir supprimer toutes les lignes sélectionnés ?
    </Text>
  );

  let renderManageActions: any = Form;

  switch (true) {
    case /inputs/.test(tabToUpdate?._id || ''):
      renderManageActions = FormInputs;
      break;
    // case /roles/.test(tabToUpdate?._id || ''):
    // renderManageActions  =
    // break;
    case /users/.test(tabToUpdate?._id || ''):
      renderManageActions = FormUsers;
      break;
  }

  // renderManageActions = (
  //   <Box>
  //     <Text>Vide = inchangé</Text>
  //     <Text>Rouge = retirer</Text>
  //     <Text>Bleu = </Text>
  //     {renderManageActions}
  //   </Box>
  // );

  return {
    runAction: {
      component: renderManageActions,
      action: handleAction,
      title: 'Actions multiple',
      actionLabel: 'Appliquer',
      canDelete: deletePermission ? 'deleteAction' : '',
    },
    deleteAction: {
      component: renderDeleteInput,
      action: handleDeleteAction,
      title: 'Supprimer entrées',
      actionLabel: 'Confirmer',
    },
  };
};

export default useManageActions;
