import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { orderBy, values } from 'lodash';
import {
  Box,
  SimpleGrid,
  Text,
  GridItem,
  Flex,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Radio,
  Tag,
  Switch,
  Badge,
} from '@chakra-ui/react';
import { allIcons, CirlceIcon } from '../utils/Icon';
import {
  addressFormat,
  getReadableColor,
  isEmpty,
  mergeWithArray,
} from '../../helpers';
import { getDistance, updateAppointment } from '../../api/planningApi';
import { colors, theme } from '../../theme';
import { generateGoogleMapsURL } from './MapUrl';
import RSelect from '../form/RSelect';
import { useSelector } from 'react-redux';
import { TState, useActions } from '../../redux/store';
import { useCanI } from '../../redux/actions';
import Loader from '../common/Loader';
import DraggableItem from '../dragAndDrop/DraggableItem';
import DropMinutes from './DropMinutes';

const daysName = ['', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];
const monthsName = [
  '',
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

const customStyle = {
  option: (provided: any) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    maxHeight: '36px',
    overflow: 'auto',
  }),
};

const CalendarColumnHeader = ({
  head,
  isCurrent,
}: {
  head: string;
  isCurrent: boolean;
}) => {
  const darkLight = useSelector((state: TState) => state.darkLight);
  return (
    <GridItem>
      <Box
        p={1}
        bgColor={isCurrent ? theme.light[darkLight] : theme.navbarBg[darkLight]}
        color={theme.darker[darkLight]}
        borderRadius='15px'
        textAlign='center'
        mb={2}
      >
        <Text
          fontWeight='bold'
          fontSize={[theme.fontSize.xxSmall, theme.fontSize.small]}
        >
          {head}
        </Text>
      </Box>
    </GridItem>
  );
};

const HourCheck = ({ hour, onCheck, selected }: any) => {
  const [currMinutes, setCurrMinutes] = useState(0);

  useEffect(() => {
    setCurrMinutes(Number(selected));
  }, [selected]);

  return (
    <>
      {['00', '15', '30', '45'].map((minutes) => (
        <Tag
          mr={1}
          backgroundColor={
            currMinutes === Number(minutes)
              ? `${colors.main}.300`
              : `${colors.main}.100`
          }
          fontSize={theme.fontSize.xSmall}
          onClick={(e) => {
            e.stopPropagation();
            const min = Number(minutes);
            setCurrMinutes(min);
            onCheck?.((date: Moment) => {
              return moment(date)?.minutes(min || 0);
            });
          }}
        >{`${hour}h${minutes}`}</Tag>
      ))}
    </>
  );
};

type TAppointments = {
  assignedUser: string;
  planningAddress_city: string;
  planningAddress_dept: string;
  planningAddress_postal: string;
  planningAddress_street: string;
  planningDate: string;
  rowData: Record<string, any>;
  user: Record<string, any>;
};

type TCalendarColumnProps = {
  appointments: Record<string, TAppointments[]>;
  day: Moment;
  onCheck?: (date: Moment) => void;
  selected?: Moment;
  selectedUsers: string[];
};

const DistanceTag = ({ appoint }: any) => {
  const rowData = useSelector((state: TState) => state.rowData);
  const modalType = useSelector((state: TState) => state.modalType);
  const planningAddress = useSelector((state: TState) => state.planningAddress);
  const isPlanningEdit = useSelector((state: TState) => state.isPlanningEdit);

  const [distance, setDistance] = useState<any>(null);
  const key = planningAddress || 'planningAddress';

  useEffect(() => {
    if (
      !appoint.planningAddress ||
      !rowData[key] ||
      appoint.planningAddress === rowData[key] ||
      (!isPlanningEdit && !/addToPlanning|updatePlanning/i.test(modalType))
    ) {
      setDistance(null);
      return;
    }

    const getIt = async () => {
      if (appoint.planningAddress !== rowData[key]) {
        const resp = await getDistance({
          origin: appoint.planningAddress,
          destination: rowData[key],
        });

        if (resp.data.distance === 0) return;

        setDistance(resp.data);
      }
    };

    getIt();
  }, [rowData]);

  return distance ? (
    <Tag
      fontSize='12px'
      mr={1}
      backgroundColor={/H/.test(distance.duration) ? 'red.400' : 'green.400'}
    >
      {`${distance.duration}/${distance.distance}`}
    </Tag>
  ) : null;
};

const CalendarColumn = ({
  appointments,
  day,
  onCheck,
  selected,
}: // selectedUsers,
TCalendarColumnProps) => {
  const rowData = useSelector((state: TState) => state.rowData);
  const user = useSelector((state: TState) => state.user);
  const darkLight = useSelector((state: TState) => state.darkLight);
  const modalType = useSelector((state: TState) => state.modalType);
  const isPlanningEdit = useSelector((state: TState) => state.isPlanningEdit);
  const { setRowData, setModalType, fetchAppointments, getDataDetail } =
    useActions();

  const canI = useCanI();

  const displayAllSlots = onCheck || isPlanningEdit;
  const isUpdate = modalType === 'updatePlanning';

  useEffect(() => {
    isUpdate && onCheck?.(moment(rowData.planningDate));
  }, []);

  return (
    <GridItem>
      {Array(13)
        .fill('')
        .map((a, idx) => {
          const appoints = orderBy(
            appointments?.[`${8 + idx}`],
            (appoint) => appoint.planningDate,
          );
          // const appointsBefore = appointments?.[`${8 + idx - 1}`];
          const isSelected = selected?.isSame(
            `${moment(day).format('YYYY-MM-DD')} ${8 + idx}:00:00`,
            'hour',
          );

          // const isAvailable = (!selectedUsers.length && appoints) ||
          // const isAvailable =
          //   !appoints?.find((appoint) =>
          //     selectedUsers.includes(appoint.assignedUser),
          //   ) &&
          //   !appointsBefore?.find((appoint) =>
          //     selectedUsers.includes(appoint.assignedUser),
          //   );

          // const [dragHoverO, setDragHoverO] = useState(false);

          if (!displayAllSlots && !appoints?.length) return null;

          return (
            <Box
              my={displayAllSlots ? 1 : 0}
              p={displayAllSlots ? 1 : 0}
              backgroundColor={displayAllSlots ? `${colors.main}.50` : 'none'}
              borderWidth={displayAllSlots ? '1px' : 0}
              borderColor={displayAllSlots ? `${colors.main}.300` : 'none'}
              borderRadius={5}
            >
              {displayAllSlots && (
                <Flex
                  cursor={onCheck ? 'pointer' : ''}
                  onClick={() => {
                    const date = moment(day);
                    date.set('hour', 8 + idx);
                    onCheck?.(date);
                  }}
                  backgroundColor={
                    isSelected ? `${colors.main}.600` : `${colors.main}.50`
                  }
                  _hover={
                    isPlanningEdit
                      ? {}
                      : {
                          backgroundColor: isSelected
                            ? `${colors.main}.600`
                            : `${colors.main}.100`,
                        }
                  }
                  borderRadius={5}
                  alignItems='center'
                  px={2}
                  py={0.5}
                >
                  {!isSelected && <Text fontWeight={500}>{`${8 + idx}H`}</Text>}
                  {onCheck && (
                    <Flex flexGrow={1}>
                      {isSelected && (
                        <Box onClick={(e) => e.stopPropagation()}>
                          <HourCheck
                            hour={8 + idx}
                            onCheck={onCheck}
                            selected={selected?.format('mm')}
                          />
                        </Box>
                      )}
                      <Box flexGrow={1} />
                      <Radio isChecked={isSelected} />
                    </Flex>
                  )}
                  {!onCheck && isPlanningEdit && (
                    <DropMinutes day={day} idx={idx} />
                  )}
                </Flex>
              )}
              {appoints?.map((appoint: any) => {
                const isOwner =
                  appoint.createdBy?._id === user._id ||
                  appoint.assignedUser === user._id ||
                  user.isAdmin;

                return (
                  <Accordion allowToggle mb={1} defaultIndex={onCheck ? [] : 0}>
                    <AccordionItem position='relative' border='none' mt={2}>
                      <DraggableItem
                        id={appoint._id}
                        noDrag={
                          !isPlanningEdit ||
                          appoint.isConfirmed ||
                          isUpdate ||
                          !isOwner
                        }
                        onDrag={() => setRowData(appoint)}
                        onCancel={() => {
                          setRowData({});
                        }}
                        type='CALENDAR'
                      >
                        <AccordionButton
                          px={1}
                          height={6}
                          backgroundColor={
                            appoint.user?.userColor || 'lightgray'
                          }
                          color={getReadableColor(appoint.user?.userColor)}
                          borderRadius='5px 5px 0 0'
                          _hover={{
                            borderColor: appoint.user?.userColor,
                            opacity: 0.8,
                          }}
                          fontSize={theme.fontSize.small}
                          cursor={
                            isPlanningEdit && isOwner ? 'grab' : 'pointer'
                          }
                        >
                          <Flex w='100%' alignItems='center'>
                            <Text fontWeight='bold' mr={2}>
                              {moment(appoint.planningDate)
                                .format('HH:mm')
                                .replace(':', 'h')}
                            </Text>
                            <Text textTransform='capitalize'>
                              {appoint.user?.name || 'Non assigné'}
                            </Text>
                            <Box flexGrow={1} />
                            <DistanceTag appoint={appoint} />
                            {appoint.planningAddress_postal}
                            {appoint.isConfirmed && (
                              <CirlceIcon
                                boxSize={theme.fontSize.big}
                                as={allIcons.HiCheck}
                                backgroundColor={
                                  appoint.isConfirmed ? 'green.300' : ''
                                }
                                ml={1}
                                p={0.5}
                                color={theme.darker[darkLight]}
                              />
                            )}
                          </Flex>
                        </AccordionButton>
                      </DraggableItem>
                      <AccordionPanel
                        bgColor={!isOwner ? theme.btnBg[darkLight] : 'auto'}
                        cursor={
                          isPlanningEdit && !isOwner ? 'not-allowed' : 'default'
                        }
                        border='1.5px solid'
                        borderColor='gray.400'
                        borderRadius={5}
                        borderTopWidth={0}
                        fontSize={theme.fontSize.small}
                        pt={2}
                        mt={-1}
                        pb={1}
                      >
                        <Flex>
                          <Text fontWeight='bold' mr={1}>
                            Adresse
                          </Text>
                          :
                          <Text ml={1}>
                            {addressFormat({
                              street: appoint.planningAddress_street,
                              city: appoint.planningAddress_city,
                              postal: appoint.planningAddress_postal,
                            })}
                          </Text>
                        </Flex>
                        {appoint.rowData.map((data: any) => (
                          <Flex mb={0.5}>
                            <Text
                              fontWeight='bold'
                              mr={1}
                              textTransform='capitalize'
                            >
                              {data.label}
                            </Text>
                            :
                            {data.color ? (
                              <Badge
                                bgColor={data.color}
                                my='auto'
                                borderRadius={5}
                                ml={1}
                                color={getReadableColor(data.color)}
                                fontSize={theme.fontSize.small}
                              >
                                {data.value}
                              </Badge>
                            ) : data.colors?.length ? (
                              data.colors.map((c: string, idx: number) => (
                                <Badge
                                  bgColor={c}
                                  my='auto'
                                  borderRadius={5}
                                  ml={1}
                                  color={getReadableColor(c)}
                                  fontSize={theme.fontSize.xSmall}
                                >
                                  {data.value?.[idx]}
                                </Badge>
                              ))
                            ) : (
                              <Text ml={1}>{data.value}</Text>
                            )}
                          </Flex>
                        ))}
                        <Flex py={1}>
                          <Box flexGrow={1} />
                          <Tooltip label='Y aller'>
                            <span>
                              <CirlceIcon
                                boxSize='25px'
                                as={allIcons.HiMapPin}
                                onClick={() =>
                                  generateGoogleMapsURL(
                                    appoint.planningAddressDescription,
                                  )
                                }
                              />
                            </span>
                          </Tooltip>
                          {canI('planning', 'confirm') && (
                            <Tooltip
                              label={
                                appoint.isConfirmed
                                  ? 'Déconfirmer'
                                  : 'Confirmer'
                              }
                            >
                              <span>
                                <CirlceIcon
                                  boxSize='25px'
                                  as={allIcons.HiCheck}
                                  backgroundColor={
                                    appoint.isConfirmed ? 'green.400' : ''
                                  }
                                  color={
                                    appoint.isConfirmed
                                      ? theme.normal[darkLight]
                                      : ''
                                  }
                                  cursor='pointer'
                                  onClick={async () => {
                                    await updateAppointment(appoint._id, {
                                      isConfirmed: !appoint.isConfirmed,
                                    });
                                    await fetchAppointments();
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                          {canI('planning', 'update') && isOwner && (
                            <>
                              <Tooltip label='Modifier RDV'>
                                <span>
                                  <CirlceIcon
                                    boxSize='25px'
                                    as={allIcons.HiCalendarDays}
                                    onClick={() => {
                                      setRowData(appoint);
                                      setModalType('updatePlanning');
                                    }}
                                  />
                                </span>
                              </Tooltip>
                              <Tooltip label='Modifier dossier'>
                                <span>
                                  <CirlceIcon
                                    boxSize='25px'
                                    as={allIcons.HiPencilSquare}
                                    onClick={() => {
                                      getDataDetail({ rowId: appoint.rowId });
                                      setRowData(appoint.formData || {});
                                      setModalType('editEntry');
                                    }}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}
                          {canI('planning', 'delete') && (
                            <Tooltip label='Supprimer le rdv'>
                              <span>
                                <CirlceIcon
                                  boxSize='25px'
                                  as={allIcons.HiMiniXMark}
                                  onClick={() => {
                                    setRowData(appoint);
                                    setModalType('deletePlanning');
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                );
              })}
            </Box>
          );
        })}
    </GridItem>
  );
};

const Calendar = ({
  selectedDate,
  setSelectedDate,
  datas,
}: {
  selectedDate?: Moment;
  setSelectedDate?: (date: Moment) => void;
  datas?: Record<string, any>;
}) => {
  const isMobile = useSelector((state: TState) => state.isMobile);
  const isTablet = useSelector((state: TState) => state.isTablet);
  const appointments = useSelector((state: TState) => state.appointments);
  const isPlanningEdit = useSelector((state: TState) => state.isPlanningEdit);
  const filterActive = useSelector((state: TState) => state.filterActive);
  const darkLight = useSelector((state: TState) => state.darkLight);
  const modalType = useSelector((state: TState) => state.modalType);
  const planningTab = useSelector((state: TState) => state.planningTab);
  const userList = useSelector((state: TState) => state.userList);
  const pendings = useSelector((state: TState) => state.pendings);
  const calendarCurrentDay = useSelector(
    (state: TState) => state.calendarCurrentDay,
  );

  const {
    setIsPlanningEdit,
    getUsersList,
    setCalendarCurrentDay: setCurrDay,
  } = useActions();

  const canI = useCanI();

  const currDay = moment(calendarCurrentDay);

  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [filterType, setFilterType] = useState<string>();

  const { assignedUser, planningType } = datas || {};
  const showFilter = /addToPlanning|updatePlanning/i.test(modalType);

  const isPC = !isMobile && !isTablet;
  const nbCol = isPC ? 5 : 1;

  const days = isPC
    ? Array(nbCol)
        .fill('')
        .map((d, idx) => moment(currDay).startOf('isoWeek').add(idx, 'day'))
    : [moment(currDay)];

  const selectDays = Array(5)
    .fill('')
    .map((d, idx) => moment(currDay).startOf('isoWeek').add(idx, 'day'));

  const changeDay = (moins?: boolean) => {
    setCurrDay((c: any) => {
      const nDate = moment(c);
      nDate[moins ? 'subtract' : 'add'](7, 'day');
      return nDate.toISOString();
    });
  };

  const updateUsers = (newSelected: any[]) => {
    if (!newSelected.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(newSelected);
    }
  };

  useEffect(() => {
    setCurrDay(moment().toISOString());
    getUsersList();
  }, []);

  useEffect(() => {
    if (!assignedUser) return;

    const currUser = userList.find((option) => option._id === assignedUser);
    currUser &&
      setSelectedUsers([{ value: currUser._id, label: currUser.name }]);
  }, [assignedUser]);

  useEffect(() => {
    if (isEmpty(planningType)) return;
    setFilterType(planningType);
  }, [planningType]);

  const currAppoints =
    filterType != null
      ? appointments[filterType]
      : values(appointments).reduce(
          (prev, val) => mergeWithArray(prev, val),
          {},
        );

  const filteredAppoint = !currAppoints
    ? []
    : !selectedUsers?.length
    ? values(currAppoints).reduce((prev, val) => mergeWithArray(prev, val), {})
    : selectedUsers.reduce(
        (prev, user) => mergeWithArray(prev, currAppoints[user.value]),
        {},
      );

  const allUsers = userList
    .filter((u) => u.isPlanable)
    .map((u) => ({
      value: u._id,
      label: u.name,
    }));

  const planningTypeOptions = planningTab?.planningType?.formOptions.map(
    (fo: any) => fo.label,
  );

  const isUAMobile =
    /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  const displayUpdateRDV =
    !setSelectedDate && canI('planning', 'update') && !isUAMobile;
  const sizes = ['100%', '100%', 'auto'];

  const isPending = pendings['global/async/fetchAppointments/pending'] > 0;

  return (
    <>
      {isPending && <Loader />}
      {(filterActive || showFilter) && (
        <Flex
          position='relative'
          mb={theme.margin}
          alignItems='center'
          flexWrap='wrap'
          justify='space-between'
          gap={3}
          bgColor={theme.navbarBg[darkLight]}
          borderRadius='15px'
          p={3}
        >
          <Box flexGrow={1} w={sizes}>
            <Text fontSize={theme.fontSize.small}>Filtre utilisateur :</Text>
            <RSelect
              placeholder='Tous les utilisateurs'
              styles={customStyle}
              closeMenuOnSelect={false}
              isMulti
              value={selectedUsers.length ? selectedUsers : []}
              onChange={updateUsers as any}
              options={allUsers}
            />
          </Box>
          {planningTypeOptions.length && (
            <Box flexGrow={1} w={sizes}>
              <Text fontSize={theme.fontSize.small}>
                Filtre type de planning :
              </Text>
              <RSelect
                placeholder='Tous les planning'
                styles={customStyle}
                value={planningTab?.planningType?.formOptions.find(
                  (fo: any) => fo.value === filterType,
                )}
                onChange={(e: any) => setFilterType(e?.value)}
                options={planningTab?.planningType?.formOptions}
                isClearable
              />
            </Box>
          )}
        </Flex>
      )}
      <Box
        position='relative'
        bgColor={theme.normal[darkLight]}
        borderRadius='15px'
        borderBottomRadius={0}
        p={3}
      >
        <Flex w='100%' mb={4} alignItems='center' userSelect='none'>
          <CirlceIcon
            as={allIcons.HiChevronLeft}
            onClick={() => changeDay(true)}
            boxSize={theme.fontSize.headerBig}
          />
          <Text
            textAlign='center'
            flexGrow={1}
            fontWeight='bold'
            fontSize={theme.fontSize.big}
            color={theme.mainBis[darkLight]}
          >
            {/* Semaine {currDay.week()}  */}
            {monthsName[currDay.format('M') as any]}
          </Text>
          <CirlceIcon
            as={allIcons.HiChevronRight}
            onClick={() => changeDay(false)}
            boxSize={theme.fontSize.headerBig}
          />
          <Tooltip
            label={`${isPC ? 'Semaine en cours' : "Ajourd'hui"}`}
            placement='right'
          >
            <span>
              <CirlceIcon
                mx={3}
                as={allIcons.HiCalendarDays}
                onClick={() =>
                  setCurrDay(isPC ? moment().startOf('isoWeek') : moment())
                }
                boxSize={theme.fontSize.headerBig}
              />
            </span>
          </Tooltip>
          {displayUpdateRDV && (
            <Flex alignItems='center'>
              <Text
                mr={2}
                fontSize={theme.fontSize.small}
                color={theme.mainBis[darkLight]}
                fontWeight='500'
              >
                Modifier les RDV
              </Text>
              <Switch
                isChecked={isPlanningEdit}
                onChange={(e) => setIsPlanningEdit(e.target.checked)}
              />
            </Flex>
          )}
        </Flex>
        <SimpleGrid columns={5} spacing={1}>
          {selectDays.map((day) => (
            <Box
              onClick={() => (isPC ? null : setCurrDay(moment(day)))}
              cursor={isPC ? 'default' : 'pointer'}
            >
              <CalendarColumnHeader
                key={moment(day).format('DD/MM/YYYY')}
                head={`${daysName[day.format('d') as any]} ${day.format('D')}`}
                isCurrent={!isPC && day.isSame(currDay, 'day')}
              />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
      <Flex overflow='hidden' height='100%' flexDirection='column'>
        <SimpleGrid
          flexGrow={1}
          overflow='auto'
          columns={nbCol}
          spacing={4}
          bgColor='#fff'
          p={3}
          borderRadius='15px'
          borderTopLeftRadius={0}
          borderTopRightRadius={0}
        >
          {days.map((day) => (
            <CalendarColumn
              key={`index-${day.format('DD/MM/YYYY')}`}
              appointments={filteredAppoint[day.format('DD/MM/YYYY')]}
              onCheck={setSelectedDate}
              selected={selectedDate}
              day={day}
              selectedUsers={selectedUsers.map(({ value }) => value)}
            />
          ))}
        </SimpleGrid>
      </Flex>
    </>
  );
};

export default Calendar;
