import { useSelector } from 'react-redux';
import { Box, Button, Collapse, Flex } from '@chakra-ui/react';
import { TState, useActions } from '../../../redux/store';
import { theme } from '../../../theme';
import { entries, keyBy } from 'lodash';
import useForm, { TField } from '../../form/Form';
import { useEffect } from 'react';
import { Table } from '@tanstack/table-core/build/lib/types';

type TDynamicFilters = {
  table: Table<any>;
};

const getAddressKey = (label: string) => {
  switch (true) {
    case /_postal/.test(label):
      return 'postal';
    case /_dept/.test(label):
      return 'dept';
    case /_city/.test(label):
      return 'city';
    case /_street/.test(label):
      return 'street';
  }
};

const DynamicFilters = ({ table }: TDynamicFilters) => {
  const dataInputs = useSelector((state: TState) => state.dataInputs);
  const modalType = useSelector((state: TState) => state.modalType);
  const filterActive = useSelector((state: TState) => state.filterActive);
  const darkLight = useSelector((state: TState) => state.darkLight);

  const { setColumnFilters } = useActions();

  const inputsById = keyBy(
    dataInputs.filter((input) =>
      /users|address|select|number|date/.test(input.type),
    ),
    'name_id',
  );

  const filterInputs = table.getAllColumns().reduce((prev, col) => {
    const realInput =
      inputsById[
        `${col.columnDef.meta?._id}`.replace(
          /_code_postal|_rue|_departement|_ville/,
          '',
        )
      ];

    const isNbCol = col.id === 'N°';

    if (!realInput?.name_id && !isNbCol) {
      return prev;
    }

    // @TODO add filtering only for used vlues
    // if (realInput?.options?.length && !isNbCol) {
    //   const uniqValues = Array.from(col.getFacetedUniqueValues().keys());
    //   realInput.options=realInput.options.filter((opt) => uniqValues.includes(opt))
    // }

    const filterInput: any = /select/.test(realInput?.type)
      ? { ...realInput, type: 'multiselect' }
      : /users/.test(realInput?.type)
      ? { ...realInput, isMulti: true }
      : /date/.test(realInput?.type)
      ? { ...realInput, type: 'date_range' }
      : /address/.test(realInput?.type)
      ? {
          ...realInput,
          type: 'text',
          label: `${(col.columnDef.meta as any)?.name}`,
          name_id: `${realInput.name_id}_${getAddressKey(col.id)}`,
        }
      : /number/.test(realInput?.type)
      ? { ...realInput, type: 'number_range' }
      : isNbCol
      ? {
          ...realInput,
          type: 'number',
          label: 'Nombre de lignes',
          name_id: 'rowNb',
        }
      : { ...realInput };

    return [...prev, filterInput];
  }, [] as TField[]);

  const { Form, formData, setFormData } = useForm(
    filterInputs,
    undefined,
    undefined,
    true,
  );

  useEffect(() => {
    setColumnFilters(entries(formData).map(([k, v]) => ({ id: k, value: v })));
  }, [formData]);

  const sizes = [1, 2, 3, 4, 5, 6];

  return (
    <Box className='dynamic-filters' zIndex={2} mb={3}>
      <Collapse in={filterActive}>
        <Box p={3} borderRadius='15px' backgroundColor={theme.none[darkLight]}>
          <Flex flexWrap='wrap' gap={2}>
            {Form.map((f) => (
              <Box w={sizes.map((s) => `calc((100% - ${s - 1} * 8px) / ${s})`)}>
                {f}
              </Box>
            ))}
          </Flex>
          <Button onClick={() => setFormData({})} mt={3} right={0}>
            Vider les filtres
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};

export default DynamicFilters;
