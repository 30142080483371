import { useEffect, useMemo, useRef } from 'react';
import { entries } from 'lodash';
import { useSelector } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
import { addInput, deleteInput, updateInput } from '../../../api/tabsApi';
import useForm, { TField } from '../../form/Form';
import { TState, useActions } from '../../../redux/store';
import { useCanI } from '../../../redux/actions';

export const addressInputs = ['rue', 'code postal', 'ville', 'departement'];
export const addressInputskeys = ['street', 'postal', 'city', 'dept'];
export const addressInputsRoles = [
  'street',
  'postal',
  'city',
  'dept',
  'showValidation',
];

const typesOfInput = [
  { label: 'Text', value: 'text' },
  { label: 'Nombre', value: 'number' },
  { label: 'Date', value: 'date' },
  { label: 'Liste', value: 'select' },
  { label: 'Liste multiple', value: 'multiselect' },
  { label: 'Adresse', value: 'address' },
  { label: 'Téléphone', value: 'tel' },
  { label: 'Utilisateur', value: 'users' },
  { label: 'Oui/Non', value: 'switch' },
];

export const typesOfInputOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Nombre', value: 'number' },
  { label: 'Date', value: 'date' },
  { label: 'Liste', value: 'select' },
  { label: 'Liste multiple', value: 'multiselect' },
  { label: 'Adresse rue', value: 'adresse_street' },
  { label: 'Adresse ville', value: 'adresse_city' },
  { label: 'Adresse code postal', value: 'adresse_postal' },
  { label: 'Téléphone', value: 'tel' },
  { label: 'Utilisateur', value: 'users' },
  { label: 'Oui/Non', value: 'switch' },
  { label: 'Fichiers', value: 'files' },
  { label: 'RDV', value: 'planning' },
];

const getFields = (modalType: string, userList: any[]): TField[] => [
  {
    type: 'select',
    name: 'type',
    label: 'Type de champ',
    disabled: modalType === 'editInput',
    options: typesOfInput,
  },
  {
    type: 'text',
    label: 'Nom du champ',
    name: 'label',
    condition: (data) => data?.type,
  },
  {
    type: 'text',
    label: 'ID du champ (si Nom existe déjà)',
    name: 'name_id',
    condition: (data) => data?.type && modalType === 'addInput',
  },
  {
    type: 'text',
    label: 'Unité',
    name: 'unity',
    condition: (data) => data?.type === 'number',
  },
  {
    type: 'selectInput',
    label: 'Éditer la liste',
    name: 'options',
    condition: (data) => /select/.test(data?.type),
  },
  ...addressInputs.map(
    (input, idx) =>
      ({
        type: 'checkbox',
        label: input,
        name: addressInputskeys[idx],
        condition: (data) => data?.type && data?.type === 'address',
      } as TField),
  ),
  {
    type: 'checkbox',
    label: 'Est valide',
    name: 'showValidation',
    condition: (data) => data?.type && data?.type === 'address',
  },
  {
    type: 'multiSelect',
    label: 'Utilisateurs',
    name: 'users',
    options: userList.map((user) => ({
      label: user.name,
      value: user._id,
    })) as any,
    condition: (data) => data?.type && data?.type === 'users',
  },
  {
    type: 'checkbox',
    label: 'Champ obligatoire',
    name: 'required',
    condition: (data) => data?.type,
  },
  {
    type: 'createSelect',
    label: 'Groupe',
    name: 'group',
    condition: (data) => data?.type,
  },
];

const useManageInputs = () => {
  const userList = useSelector((state: TState) => state.userList);
  const rowData = useSelector((state: TState) => state.rowData);
  const modalType = useSelector((state: TState) => state.modalType);

  const { setRowData, onClose, getInputs, setModalType } = useActions();

  const canI = useCanI();
  const toast = useToast();

  const delay = useRef<null | any>(null);

  const fields = useMemo(
    () => getFields(modalType, userList),
    [modalType, userList],
  );

  const { Form, formData } = useForm(fields);

  useEffect(() => {
    const regex =
      formData.type === 'address'
        ? /_id|type|label|name_id|group|required|showValidation|street|city|postal|dept/
        : /select/.test(formData.type)
        ? /_id|type|label|name_id|group|required|colors|options/
        : formData.type === 'number'
        ? /_id|type|label|name_id|unity|group|required/
        : formData.type === 'users'
        ? /_id|type|label|name_id|unity|group|options|required/
        : /_id|type|label|name_id|group|required/;

    const data = entries(formData).reduce(
      (prev, [k, v]) => ({
        ...prev,
        [k]: regex.test(k) ? v : null,
      }),
      {},
    );

    !/del/.test(modalType) && setRowData(data);
  }, [formData.type]);

  const handleAddInput = async () => {
    setTimeout(async () => {
      if (!formData.label) {
        toast({
          title: 'Vous devez donner un nom au champ',
          status: 'warning',
        });
        return;
      }

      if (/select/.test(formData.type) && !formData.options?.length) {
        toast({
          title: 'Vous devez ajouter au moins une option',
          status: 'warning',
        });
        return;
      }

      const resp = await addInput(delay.current);
      delay.current.null;
      await getInputs({ forced: true });

      setModalType('editInput');
      setRowData(resp.data);
    }, 100);
  };

  const handleEditInput = async () => {
    setTimeout(async () => {
      await updateInput(delay.current);
      delay.current.null;
      await getInputs({ forced: true });
    }, 100);
  };

  const handleDeleteInput = async () => {
    await deleteInput(rowData._id);
    await getInputs({ forced: true });
    onClose();
  };

  const renderManageInputs = Form;

  const deletePermission = canI('inputs', 'delete');

  const renderDeleteInput = (
    <Text>Confirm you want to delete this entry ?</Text>
  );

  useEffect(() => {
    if (/select/.test(formData.type)) {
      if (formData.orderedOptions) {
        delay.current = { ...formData, options: formData.orderedOptions };
      }
    } else {
      delay.current = formData;
    }
  }, [formData]);

  return {
    addInput: {
      component: renderManageInputs,
      action: handleAddInput,
      title: 'Ajouter champ',
      actionLabel: 'Ajouter',
    },
    editInput: {
      component: renderManageInputs,
      canDelete: deletePermission ? 'deleteInput' : '',
      action: handleEditInput,
      title: 'Editer champ',
      actionLabel: 'Sauvegarder',
    },
    deleteInput: {
      component: renderDeleteInput,
      action: handleDeleteInput,
      title: 'Supprimer champ',
      actionLabel: 'Confirmer',
    },
  };
};

export default useManageInputs;
