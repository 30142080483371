import { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { addUser, deleteUser, updateUser } from '../../../api/tabsApi';
import useForm, { TField } from '../../form/Form';
import { TUser } from '../../login/Login';
import ColorPicker, { allColors } from '../../form/ColorPicker';
import { getRandom } from '../../../helpers';
import { useCanI } from '../../../redux/actions';
import { useSelector } from 'react-redux';
import { TState, useActions } from '../../../redux/store';

const useManageUsers = () => {
  const userList = useSelector((state: TState) => state.userList);
  const roleList = useSelector((state: TState) => state.roleList);
  const rowData = useSelector((state: TState) => state.rowData);
  const modalType = useSelector((state: TState) => state.modalType);
  const isSuper = useSelector((state: TState) => state.isSuper);
  const isAdmin = useSelector((state: TState) => state.isAdmin);

  const canI = useCanI();
  const { onClose, getUsersList } = useActions();

  const [userColor, setUserColor] = useState('');

  const handleAddUser = async (formData: TUser) => {
    const resp = await addUser(formData);

    if (resp) {
      getUsersList({ forced: true });
      onClose();
    }
  };

  const handleEditUser = async (formData: TUser) => {
    const resp = await updateUser(rowData._id, formData);

    if (resp) {
      getUsersList({ forced: true });
      onClose();
    }
  };

  const handleDeleteUser = async () => {
    await deleteUser(rowData._id);
    getUsersList({ forced: true });
    onClose();
  };

  const options = roleList?.map((role) => ({
    label: role.name,
    value: role._id,
  }));

  const fields: TField[] = [
    { label: 'Nom prénom', name: 'name', type: 'text' },
    { label: 'Login', name: 'login', type: 'text' },
    {
      label: 'Pass',
      name: 'pass',
      type: 'password',
      condition: () => modalType === 'editUser',
    },
    {
      label: 'Role',
      name: 'role_id',
      type: 'select',
      options,
      selected: rowData.role_id,
      condition: () => !isSuper,
    },
    {
      label: 'Utilisateurs supervisés',
      name: 'assignedUsers',
      type: 'users',
      isMulti: true,
      selected: rowData.assignedUsers,
      condition: () => !isSuper,
    },
    {
      label: 'Admin',
      name: 'isAdmin',
      type: 'checkbox',
      condition: () => isSuper || isAdmin,
    },
    { label: 'Planifiable', name: 'isPlanable', type: 'checkbox' },
    { label: 'Désactivé', name: 'blocked', type: 'checkbox' },
    { label: 'Sans restriction', name: 'noRestrict', type: 'checkbox' },
    {
      label: 'Modèle',
      name: 'copyId',
      type: 'select',
      options: userList.map((u) => ({
        label: u.name || u.login,
        value: u._id,
      })),
      condition: () => isSuper && modalType === 'addUser',
    },
  ];

  const handleSubmit = () => {
    const submitFn = modalType === 'addUser' ? handleAddUser : handleEditUser;
    submitFn({ ...fd, userColor } as any);
  };

  const { Form, formData: fd } = useForm(fields);

  useEffect(() => {
    setUserColor(rowData.userColor || getRandom(allColors));
  }, [rowData.userColor]);

  const deletePermission = canI('users', 'delete');

  const renderManageUsers = (
    <>
      {Form.map((f: any) => {
        return f.props.field.name === 'isPlanable' && fd.isPlanable ? (
          <Box position={'relative'}>
            {f}
            <Box position='absolute' top={0} left='calc(30% + 30px)'>
              <ColorPicker
                bg={userColor}
                onChange={(e) => {
                  setUserColor(e.hex);
                }}
              />
            </Box>
          </Box>
        ) : (
          f
        );
      })}
    </>
  );

  const renderDeleteUser = <Text>Confirm you want to delete this entry ?</Text>;

  return {
    addUser: {
      component: renderManageUsers,
      action: handleSubmit,
      title: 'Ajouter utilisateur',
      actionLabel: 'Ajouter',
    },
    editUser: {
      component: renderManageUsers,
      canDelete: deletePermission ? 'deleteUser' : '',
      action: handleSubmit,
      title: 'Editer utilisateur',
      actionLabel: 'Sauvegarder',
    },
    deleteUser: {
      component: renderDeleteUser,
      action: handleDeleteUser,
      title: 'Supprimer utilisateur',
      actionLabel: 'Confirmer',
    },
  };
};

export default useManageUsers;
