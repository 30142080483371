import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { keys, sum, values } from 'lodash';
import { Box, Checkbox } from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
  ColumnMeta,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { getValue, strNomalize } from '../../helpers';
import { TColumn } from './DynamicTable';
import { theme } from '../../theme';
import { TState, useActions } from '../../redux/store';
import useFilters from './tableComponents/useFilters';

type TTable = {
  data: any[];
  columns: TColumn[];
  onLoad?: () => void;
  editEntryKey?: string;
  hideButtons?: boolean;
  noResize?: boolean;
  onEditClick?: any;
  pendingKey: string;
};

export const NB_WIDTH = 60;
export const DEFAULT_WIDTH = 100;
export const TABLE_FONT_SIZE = theme.fontSize.table;

const computeWidths = (
  minWidths: number[],
  withNav: boolean,
  isOpen: boolean,
) => {
  const maxWidth = getValue('#reactTableContainer', 'width');
  const navWidth = 320 - 48;
  const computedWidth = withNav
    ? maxWidth - navWidth * (isOpen ? 1 : -1)
    : maxWidth;

  const diff = computedWidth - sum(minWidths);
  const eachWidth = diff / (minWidths.length - 1);

  return eachWidth;
};

const numberSort = (rowA: any, rowB: any, columnId: string, desc: string) => {
  const a = rowA.values[columnId];
  const b = rowB.values[columnId];
  if (a === b) return 0;
  if (desc) {
    return a > b ? -1 : 1;
  } else {
    return a > b ? 1 : -1;
  }
};

const handleToggleAllVisibleRows = (table: any, e: any) => {
  const isChecked = e.target.checked;

  const end =
    table.getState().columnFilters.find((f: any) => f.id === 'rowNb')?.value ||
    undefined;

  const visibleRows = table.getRowModel().rows.slice(0, end);

  visibleRows.forEach((row: any) => {
    row.toggleSelected(isChecked);
  });
};

const handleIsAllChecked = (table: any) => {
  const end =
    table?.getState().columnFilters.find((f: any) => f.id === 'rowNb')?.value ||
    undefined;

  const visibleRows = table.getRowModel().rows.slice(0, end);
  const checked = visibleRows.filter((row: any) => row.getIsSelected());

  return checked.length === visibleRows.length;
};

const NBHeader = ({ table }: any) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(handleIsAllChecked(table));
  });

  return (
    <Box cursor='default'>
      N°
      <Checkbox
        ml={2}
        isChecked={isChecked}
        onChange={(e) => handleToggleAllVisibleRows(table, e)}
      />
    </Box>
  );
};

const useTableContainer = ({
  noResize,
  data,
  columns,
  editEntryKey,
  onLoad,
  onEditClick: oec,
  pendingKey,
}: TTable) => {
  const globalFilter = useSelector((state: TState) => state.globalFilter);
  const columnFilters = useSelector((state: TState) => state.columnFilters);
  const isHovered = useSelector((state: TState) => state.isHovered);
  const pendings = useSelector((state: TState) => state.pendings);

  const {
    setModalType,
    setRowSelected,
    setRowData,
    getDataDetail,
    setColumnFilters,
  } = useActions();

  const filterFn = useFilters();

  const [widthSize, setWidthSize] = useState(window.innerWidth);
  const [columnSizing, setColumnSizing] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const finalCol: any[] = useMemo(
    () =>
      !columns?.length
        ? []
        : [
            {
              header: ({ table }: any) => <NBHeader table={table} />,
              id: 'N°',
              accessorFn: (row: any, rowIdx: number) => rowIdx + 1,
              sortType: numberSort,
              enableSorting: false,
              size: NB_WIDTH,
              meta: {
                _id: 'tableIndex',
                tableIndex: true,
                size: NB_WIDTH,
              } as ColumnMeta<any, any>,
            },
            ...columns.map((col) =>
              (col.meta as any)?.type === 'number'
                ? {
                    ...col,
                    sortType: numberSort,
                  }
                : col,
            ),
          ],
    [columns],
  );

  const table = useReactTable({
    data,
    defaultColumn: {
      size: DEFAULT_WIDTH,
      filterFn,
      sortingFn: (rowA, rowB, columnId) => {
        const valueA = `${rowA.renderValue(columnId) ?? '-'}`.replace(
          /\s/gi,
          '',
        );
        const valueB = `${rowB.renderValue(columnId) ?? '-'}`.replace(
          /\s/gi,
          '',
        );

        if (valueA === valueB) return 0;

        if (valueA === '-') return 1;
        if (valueB === '-') return -1;

        return strNomalize(valueA) < strNomalize(valueB) ? -1 : 1;
      },
    },
    columns: finalCol,
    columnResizeMode: 'onChange',
    state: {
      columnSizing,
      rowSelection,
      columnFilters: columnFilters as any,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onColumnSizingChange: setColumnSizing,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: (row, columnId, filterValue) => {
      const norm = values(
        row
          .getAllCells()
          .map((cell) =>
            cell.column.id === 'N°'
              ? ''
              : strNomalize(`${cell.renderValue()}`.replace(/ /g, '')),
          ),
      ).join('__');

      return norm.includes(strNomalize(filterValue.replace(/ /g, '')));
    },
  });

  const parentRef = useRef<any>();

  const { getHeaderGroups, getRowModel } = table;
  const end = columnFilters.find((f) => f.id === 'rowNb')?.value || undefined;

  const rows = getRowModel().rows.slice(0, end);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  useEffect(() => {
    const handleResize = () => {
      setWidthSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const resize = (withNav = false) => {
    const defaultSizes = [
      NB_WIDTH,
      ...Array(columns?.length).fill(DEFAULT_WIDTH),
    ];

    const fill = computeWidths(defaultSizes, withNav, isHovered);
    const minSizes =
      fill > 0
        ? defaultSizes.map((s, idx) => (idx === 0 ? s : s + fill))
        : defaultSizes;

    getHeaderGroups()[0].headers.map(
      (h, i) => (h.column.columnDef.minSize = minSizes[i]),
    );

    setColumnSizing(minSizes);
  };

  useEffect(() => {
    setRowSelection({});
  }, [data]);

  useEffect(() => {
    resize(true);
  }, [isHovered]);

  useEffect(() => {
    resize();
  }, [widthSize, columns]);

  useEffect(() => {
    onLoad?.();
  }, [editEntryKey]);

  useEffect(() => {
    table.setGlobalFilter(globalFilter);
  }, [globalFilter]);

  useEffect(() => {
    setRowSelected(
      keys(rowSelection).map(
        (k) => table.getCoreRowModel().rowsById[k].original._id,
      ),
    );
  }, [rowSelection]);

  const onEditClick = async (row: any) => {
    oec?.(row);

    const isGetDetail = (editEntryKey || 'editEntry') === 'editEntry';

    if (isGetDetail) {
      getDataDetail({ rowId: row.original._id });
    }

    setModalType(editEntryKey || 'editEntry');
    setRowData(row.original);
  };

  const isPending = pendings[pendingKey] > 0;

  const props = useMemo(
    () => ({
      parentRef,
      table,
      totalSize: rowVirtualizer.getTotalSize(),
      virtualRows,
      noResize,
      isPending,
      rows,
      getHeaderGroups,
      onEditClick,
    }),
    [
      table,
      virtualRows,
      noResize,
      rowVirtualizer.getTotalSize(),
      isPending,
      rows,
    ],
  );

  return props;
};

export type TTableContainer = ReturnType<typeof useTableContainer>;
export default useTableContainer;
